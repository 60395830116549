import { makeStyles, Theme } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import { Page } from 'components';
import { INCOME_STATEMENT_BASE_URL } from 'constants/url';
import { format, startOfMonth } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { dummyMetaData } from 'utils/dummy';
import IncomeStatementForm from './components/CreatePage';
import { GREEN, WHITE } from 'constants/colors';

export type TypeIncomeStatement = {
  isLoading: boolean;
  data: IncomeStatement[];
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  }
}));

const BalanceSheetPage = () => {
  const classes = useStyles();
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const [incomeStatement, setIncomeStatement] = useState<TypeIncomeStatement>({
    isLoading: false,
    data: []
  });
  const [incomeStatementId, setIncomeStatementId] = useState<IncomeStatement | null>(null);
  const [id, setId] = useState<number>(0);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const handleOpenCreate = () => setIsCreate(true);
  const handleCloseCreate = useCallback(() => setIsCreate(false), []);
  const handleCloseUpdate = useCallback(() => setIsUpdate(false), []);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [meta, setMeta] = useState<MetaData>(dummyMetaData);
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [queryString, setQueryString] = useState<string>();
  const [isAction, setIsAction] = useState<{ isOpen: boolean; isLoading: boolean; id: number; action: 'delete' | 'update' | 'detail' }>({
    isOpen: false,
    isLoading: false,
    id: 0,
    action: 'delete'
  });

  const handleCalendarFilterClick = () => setOpenCalendarFilter(true);

  const fetchData = useCallback(async () => {
    setIncomeStatement(prev => ({ ...prev, isLoading: true }));
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('keyword', queryString);
      }
      params.append('page', currentPage.toString());
      // params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
      // params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));
      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }
      return params;
    };

    try {
      const { data } = await axios.get(`${INCOME_STATEMENT_BASE_URL}?${getQueryParams()}`, {
        cancelToken
      });
      setIncomeStatement(prev => ({ ...prev, isLoading: false, data: data.data }));
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
    }
  }, [queryString, order, orderBy, startDate, endDate, currentPage]);

  const handleOnUpdate = async (id: number) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      const { data } = await axios.get(`${INCOME_STATEMENT_BASE_URL}/${id}`, { cancelToken: cancelTokenSource.token });
      setIncomeStatementId(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsCreate(true);
    fetchData();
  }, [startDate, endDate, isCreate]);

  return (
    <Page title='Neraca'>
      <IncomeStatementForm />
    </Page>
  );
};

export default BalanceSheetPage;
