import React, { useState, useEffect, lazy, Suspense, useCallback } from 'react';
import { Button, Container, FormControl, Grid, IconButton, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';

import axios, { CancelTokenSource } from 'axios';
import { Page, PaperCustom, StandardConfirmationDialog } from 'components';
import FlexBox from 'components/FlexBox';
import { ACCOUNT_CATEGORY_BASE_URL, INCOME_STATEMENT_BASE_URL, OPERATIONAL_BASE_URL, PRODUCT_SEARCH, STOCK_MOVEMENT_BASE_URL } from 'constants/url';

import { format, startOfMonth } from 'date-fns';
import DateRangeFilter from 'components/DateRangeFilter';
import { Today } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';
import { GREEN, WHITE } from 'constants/colors';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';
const StockMovementContent = lazy(() => import('./components/CreateIncomeStatementContent'));

const useStyles = makeStyles((theme: Theme) => ({
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  }
}));

export type TListExpand = {
  id: number | string;
  action: 'products' | 'months';
};

export type TFilters = {
  keyWord: string;
  productName: string;
  categoryId: number;
  isProductPackage: boolean;
};

export type TData<T> = {
  isLoading: boolean;
  data: T;
};

export const dummyFilters: TFilters = {
  productName: '',
  keyWord: '',
  isProductPackage: false,
  categoryId: 0
};

export type TypeOperasionalResource = {
  isLoading: boolean;
  data: any[];
};

/* Creating a dummyData object with two properties: isLoading and data. */
const dummyData = {
  isLoading: true,
  data: []
};

const dummyIncomeStatement: IncomeStatement = {
  id: 0,
  totalSales: 0,
  salesReturnBill: 0,
  salesReturn: 0,
  salesDiscount: 0,
  netRevenue: 0,
  totalPurchase: 0,
  purchaseLogisticCost: 0,
  purchaseInvoiceDiscount: 0,
  purchaseReturn: 0,
  totalPurchaseCost: 0,
  beginningInventory: 0,
  endingInventory: 0,
  costOfGoodsSold: 0,
  totalCost: 0,
  cost: 0,
  otherExpenses: 0,
  grossProfit: 0,
  profitLoss: 0,
  otherIncome: 0,
  totalIncome: 0,
  startDate: '',
  endDate: '',
  isDeleted: false,
  createdAt: ''
};

interface Props {
  id: number;
  open: boolean;
  setId: React.Dispatch<React.SetStateAction<number>>;
  setIsCreate: React.Dispatch<React.SetStateAction<boolean>>;
}

/* A function that returns a react component. */
const StockMovementPage = ({ setId, id, open, setIsCreate }: Props) => {
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [operasionalResource, setOperasionalResource] = useState<TypeOperasionalResource>({
    isLoading: false,
    data: []
  });
  const [otherExpensesCategory, setOtherExpensesCategory] = useState<TypeOperasionalResource>({
    isLoading: false,
    data: []
  });
  const [otherExpensesCategoryExpand, setOtherExpensesCategoryExpand] = useState<boolean>(false);
  const [otherIncomeCategory, setItherIncomeCategory] = useState<TypeOperasionalResource>({
    isLoading: false,
    data: []
  });
  const [otherIncomeCategoryExpand, setOtherIncomeCategoryExpand] = useState<boolean>(false);
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [incomeStatement, setIncomeStatement] = useState<TData<IncomeStatement>>({
    isLoading: dummyData.isLoading,
    data: dummyIncomeStatement
  });
  const [omset, setOmset] = useState<boolean>(false);
  const [purchase, setPurchase] = useState<boolean>(false);
  const [hpp, setHpp] = useState<boolean>(false);
  const [costOperational, setCostOperational] = useState<boolean>(false);

  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const handleCalendarFilterClick = () => setOpenCalendarFilter(true);
  const classes = useStyles();

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
    setIsCreate(false);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const fetchCost = useCallback(async () => {
    setOperasionalResource(prev => ({ ...prev, isLoading: true }));
    const getQueryParams = () => {
      const params = new URLSearchParams();
      params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
      params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));
      return params;
    };

    try {
      const { data } = await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/cost-category?${getQueryParams()}`, {
        cancelToken
      });

      setOperasionalResource(prev => ({ ...prev, isLoading: false, data: data.data }));
    } catch (error) {
      console.log(error);
    }
  }, [startDate, endDate]);

  const otherIncome = useCallback(async () => {
    setOperasionalResource(prev => ({ ...prev, isLoading: true }));
    const getQueryParams = () => {
      const params = new URLSearchParams();
      params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
      params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));
      return params;
    };

    try {
      const { data } = await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/other-income-category?${getQueryParams()}`, {
        cancelToken
      });

      setItherIncomeCategory(prev => ({ ...prev, isLoading: false, data: data.data }));
    } catch (error) {
      console.log(error);
    }
  }, [startDate, endDate]);

  const fetchOtherCost = useCallback(async () => {
    setOperasionalResource(prev => ({ ...prev, isLoading: true }));
    const getQueryParams = () => {
      const params = new URLSearchParams();
      params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
      params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));
      return params;
    };

    try {
      const { data } = await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/other-expenses-category?${getQueryParams()}`, {
        cancelToken
      });

      setOtherExpensesCategory(prev => ({ ...prev, isLoading: false, data: data.data }));
    } catch (error) {
      console.log(error);
    }
  }, [startDate, endDate]);

  const getForm = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    const getQueryParams = () => {
      const params = new URLSearchParams();

      params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
      params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));

      return params;
    };

    try {
      if (id == 0) {
        console.log('get form');
        const { data } = await axios.get(`${INCOME_STATEMENT_BASE_URL}/form?${getQueryParams()}`, {
          cancelToken
        });
        console.log(data);
        setIncomeStatement(prev => ({ ...prev, isLoading: false, data: data }));
      } else {
        console.log('get id');
        const { data } = await axios.get(`${INCOME_STATEMENT_BASE_URL}/${id}`, { cancelToken: cancelTokenSource.token });
        setIncomeStatement(prev => ({ ...prev, isLoading: false, data: data.data }));
      }
    } catch (error) {
      console.log(error);
    }
  }, [startDate, endDate]);

  const onRefresh = () => {
    setStartDate(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
    setEndDate(format(new Date(), 'yyyy-MM-dd'));
  };

  const handleDownload = useCallback(async () => {
    //setIsLoadingDownload(true);
    const params = new URLSearchParams();

    params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
    params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));

    try {
      const { data } = await axios(`${INCOME_STATEMENT_BASE_URL}/download-pdf?${params}`, { responseType: 'blob' });
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
      if (newwindow) {
        newwindow.focus();
      }
      // setIsLoadingDownload(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchCost();
    fetchOtherCost();
    getForm();
    otherIncome();
  }, [startDate, endDate, id]);

  return (
    <Page title='Laba Rugi'>
      <Container>
        <PaperCustom>
          <FlexBox container rowGap={1}>
            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbar}
              handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
              onConfirm={handleConfirmSnackbar}
              noCancelButton={true}
            />
            <Grid container direction='row' spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container direction='row' justify='space-between'>
                  <Grid item xl={7} lg={7} md={12} sm={12} xs={12} container alignItems='center'>
                    <Tooltip title='Memuat Ulang'>
                      <Button size='small' onClick={onRefresh} color='inherit' className={classes.refresh}>
                        <RefreshIcon fontSize='small' />
                      </Button>
                    </Tooltip>
                    <Typography style={{ fontSize: '1rem', fontWeight: 400, marginLeft: '15px' }}>
                      {`Laporan Laba Rugi`}

                      <Typography color='primary'>
                        Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                      </Typography>
                    </Typography>
                  </Grid>

                  <Grid container item lg={4} sm={4} md={4} justify='flex-end' alignItems='center'>
                    <Grid item>
                      <Tooltip title='Calendar filter' placement='top'>
                        <Button color='primary' variant='contained' startIcon={<Today />} onClick={handleCalendarFilterClick}>
                          Filter
                        </Button>
                      </Tooltip>
                      <Tooltip title='Download Pdf' placement='top'>
                        <Button
                          color='primary'
                          variant='contained'
                          startIcon={<PictureAsPdfRoundedIcon />}
                          onClick={handleDownload}
                          style={{ marginLeft: '15px' }}
                        >
                          Download
                        </Button>
                      </Tooltip>
                      {/* <Button aria-controls='simple-menu' aria-haspopup='true' style={{ marginLeft: '15px' }}> */}
                      {/* onClick={handleClick} */}
                      {/* Download */}
                      {/* </Button> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Suspense
              fallback={
                <Grid xs={12} container justify='center' alignItems='center'>
                  <Typography variant='h6'>Sedang Membuka...</Typography>
                </Grid>
              }
            >
              <StockMovementContent
                incomeStatement={incomeStatement}
                omset={omset}
                setOmset={setOmset}
                hpp={hpp}
                setHpp={setHpp}
                purchase={purchase}
                setPurchase={setPurchase}
                costOperational={costOperational}
                setCostOperational={setCostOperational}
                operasionalResource={operasionalResource}
                otherIncomeCategory={otherIncomeCategory}
                setOtherIncomeCategoryExpand={setOtherIncomeCategoryExpand}
                otherIncomeCategoryExpand={otherIncomeCategoryExpand}
                otherExpensesCategory={otherExpensesCategory}
                setOtherExpensesCategoryExpand={setOtherExpensesCategoryExpand}
                otherExpensesCategoryExpand={otherExpensesCategoryExpand}
              />
            </Suspense>
          </FlexBox>
          <DateRangeFilter
            openCalendarFilter={openCalendarFilter}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            handleClose={() => setOpenCalendarFilter(false)}
          />
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default StockMovementPage;
