import React, { memo, useEffect, useState } from 'react';
import {
  DialogContent,
  Dialog,
  Grid,
  Typography,
  IconButton,
  TextField,
  makeStyles,
  Button,
  CircularProgress,
  MenuItem,
  Tooltip,
  InputAdornment
} from '@material-ui/core';
import { JOURNAL_TRANSACTIONS_BASE_URL } from 'constants/url';
import { Close } from '@material-ui/icons';
import { DialogTitle, NumberFormatMask } from 'components';
import { GREY, GREY_6, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import { dummyTransactionJurnal } from 'utils/dummy';
import axios from 'axios';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles({
  container: {
    marginTop: 10,
    columnGap: '1em'
  },

  iconLarge: {
    fontSize: '3.5em'
  },
  containerImage: {
    margin: '0.7em 0',
    rowGap: '0.7em'
  },
  uploadImage: {
    padding: '1em ',
    backgroundColor: GREY,
    border: '2px dashed ' + GREY_6
  },
  browser: {
    color: PRIMARY_MOBILE,
    cursor: 'pointer'
  },
  wrapperImage: {
    columnGap: '1em'
  },
  containerBoxImage: {
    position: 'relative'
  },
  image: {
    objectFit: 'contain',
    borderRadius: '0.5em',
    height: '4em'
  },
  closeImage: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  closeIcon: {
    backgroundColor: 'rgba(32, 42, 61, 0.88)',
    borderRadius: '50%',
    cursor: 'pointer',
    color: WHITE
  },
  tabPanel: {
    flexGrow: 1,
    marginBottom: '20px',
    display: 'flex',

    maxWidth: 500,
    justifyContent: 'center'
  },
  tab: {
    minWidth: 'auto',
    padding: '10px',
    margin: '0 4px'
  }
});

interface Props {
  financialAccounts: AccountCategoryModel[];
  subAccountCategories: AccountCategoryModel[];
  journalTransactionsId: transactionJurnal | null;
  open: boolean;
  handleCloseCreate: () => void;
  reFetchData: () => void;
}

const CreateTransferInternal = ({ financialAccounts, journalTransactionsId, subAccountCategories, open, handleCloseCreate, reFetchData }: Props) => {
  const classes = useStyles();
  const [transactionJurnal, setTransactionJurnal] = useState<transactionJurnal>(dummyTransactionJurnal);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const disabled = Number(transactionJurnal.debit) == 0 || transactionJurnal.accountCategoriesId == -1 || transactionJurnal.financialAccount == -1;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setTransactionJurnal(prev => ({ ...prev, [id]: value }));
  };

  useEffect(() => {
    if (journalTransactionsId) {
      setTransactionJurnal(journalTransactionsId);
    }
  }, [journalTransactionsId]);

  const handleSubmit = async () => {
    const dataFinal = {
      ...transactionJurnal,
      type: 'Pemindahan Internal'
    };
    setIsSubmit(true);
    try {
      const { data } = await axios.post(JOURNAL_TRANSACTIONS_BASE_URL, dataFinal);
      reFetchData();
      reMounted();
    } catch (error) {
      console.log(error);
    }
  };

  const reMounted = () => {
    setTransactionJurnal(dummyTransactionJurnal);
    setIsSubmit(false);
    handleCloseCreate();
  };

  const handleCloseCreates = () => {
    reMounted();
    handleCloseCreate();
  };

  return (
    <Dialog maxWidth={'sm'} disableBackdropClick={true} aria-labelledby='customized-dialog-title' open={open}>
      <DialogTitle>
        <Grid xs={12} container justify='space-between'>
          <Typography variant='h5'>Buat Transfer Internal Baru</Typography>
          <IconButton onClick={handleCloseCreates} size='small'>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction='row'>
          <Grid xs={12}>
            <Grid container justify='space-between' spacing={2}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  id='financialAccount'
                  required
                  label='Transfer dari'
                  value={transactionJurnal.financialAccount}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setTransactionJurnal(prev => ({
                      ...prev,
                      financialAccount: Number(event.target.value)
                    }))
                  }
                  select
                  fullWidth
                >
                  {/* Opsi default dengan tooltip */}
                  <MenuItem value={-1} key={'financialAccount'}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title='Pilih akun yang menjadi sumber dana' arrow>
                        <InfoOutlinedIcon
                          style={{
                            marginRight: 8,
                            fontSize: 16,
                            color: '#888',
                            cursor: 'pointer'
                          }}
                        />
                      </Tooltip>
                      Pilih Akun
                    </span>
                  </MenuItem>

                  {/* Opsi lainnya */}
                  {financialAccounts.length > 0 &&
                    financialAccounts.map((value, index) => (
                      <MenuItem key={index} value={value.id}>
                        {value.name} - {value.number}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  id='accountCategoriesId'
                  required
                  label='Setor Ke'
                  value={transactionJurnal.accountCategoriesId}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setTransactionJurnal(prev => ({
                      ...prev,
                      accountCategoriesId: Number(event.target.value)
                    }))
                  }
                  select
                  fullWidth
                >
                  <MenuItem value={-1} key={'accountCategoriesId'}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title='Pilih akun yang menerima dana dalam perusahaan' arrow>
                        <InfoOutlinedIcon
                          style={{
                            marginRight: 8,
                            fontSize: 16,
                            color: '#888',
                            cursor: 'pointer'
                          }}
                        />
                      </Tooltip>
                      Pilih akun
                    </span>
                  </MenuItem>
                  {financialAccounts.length > 0 &&
                    financialAccounts.map((value, index) => (
                      <MenuItem key={index} value={value.id}>
                        {value.name} - {value.number}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                id='date'
                required
                fullWidth
                name='date'
                label='Tanggal'
                type='date'
                value={transactionJurnal.date}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTransactionJurnal(prev => ({ ...prev, date: event.target.value }))}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <TextField
              id='debit'
              required
              fullWidth
              placeholder='Isi nominal transaksi'
              name='debit'
              autoComplete='off'
              value={transactionJurnal.debit}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                if (/^\d*$/.test(value)) {
                  setTransactionJurnal(prev => ({
                    ...prev,
                    debit: Number(value)
                  }));
                }
              }}
              label='Jumlah'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Tooltip title='Masukkan nominal transaksi' arrow>
                      <InfoOutlinedIcon style={{ cursor: 'pointer', fontSize: 18, color: '#888' }} />
                    </Tooltip>
                  </InputAdornment>
                ),
                inputComponent: NumberFormatMask as any
              }}
            />

            <TextField
              id='description'
              label='Keterangan'
              placeholder='Masukan keterangan mengenai transaksi ini atau keterangan dari buku bank atau rekening koran'
              multiline
              rows={4}
              variant='outlined'
              fullWidth
              value={transactionJurnal.description}
              onChange={handleChange}
            />
          </Grid>

          <Grid container item lg={12} md={12} sm={12} xs={12} justify='flex-end' className={classes.container}>
            <Button color='secondary' onClick={handleCloseCreates}>
              Batal
            </Button>

            <Button onClick={handleSubmit} disabled={isSubmit || disabled}>
              {isSubmit ? <CircularProgress color='primary' size={20} /> : 'Simpan'}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default memo(CreateTransferInternal);
