import React, { memo, useEffect, useState } from 'react';
import { DialogContent, Dialog, Grid, Typography, IconButton, makeStyles, Tabs, Tab, Tooltip } from '@material-ui/core';
import { ACCOUNT_CATEGORY_BASE_URL, OPERATIONAL_BASE_URL } from 'constants/url';
import { Close, CloudDownload } from '@material-ui/icons';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { DialogTitle, NumberFormatMask } from 'components';
import { GREY, GREY_6, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import { dummyOperasional } from 'utils/dummy';
import axios from 'axios';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import ArtTrackOutlinedIcon from '@material-ui/icons/ArtTrackOutlined';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import CreateTax from './CreateTax';
import CreateAssetSale from './CreateAssetSale';
import CreateLoan from './CreateLoan';
import CreateCapitalInvestment from './CreateCapitalInvestment';
import CreateOtherIncome from './CreateOtherIncome';
import CreateTaxExpenditure from './CreateTaxExpenditure';
import CreateAssetPurchase from './CreateAssetPurchase';
import CreateInstallmentPayments from './CreateInstallmentPayments';
import CreateDividendDistribution from './CreateDividendDistribution';

const useStyles = makeStyles({
  container: {
    marginTop: 10,
    columnGap: '1em'
  },

  iconLarge: {
    fontSize: '3.5em'
  },
  containerImage: {
    margin: '0.7em 0',
    rowGap: '0.7em'
  },
  uploadImage: {
    padding: '1em ',
    backgroundColor: GREY,
    border: '2px dashed ' + GREY_6
  },
  browser: {
    color: PRIMARY_MOBILE,
    cursor: 'pointer'
  },
  wrapperImage: {
    columnGap: '1em'
  },
  containerBoxImage: {
    position: 'relative'
  },
  image: {
    objectFit: 'contain',
    borderRadius: '0.5em',
    height: '4em'
  },
  closeImage: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  closeIcon: {
    backgroundColor: 'rgba(32, 42, 61, 0.88)',
    borderRadius: '50%',
    cursor: 'pointer',
    color: WHITE
  },
  tabPanel: {
    flexGrow: 1,
    marginBottom: '20px',
    display: 'flex',

    maxWidth: 500,
    justifyContent: 'center'
  },
  tab: {
    minWidth: 'auto',
    padding: '10px',
    margin: '0 4px'
  }
});
interface Props {
  financialAccounts: AccountCategoryModel[];
  open: boolean;
  handleCloseCreate: () => void;
  reFetchData: () => void;
}

const OtherExpensesCreate = ({ financialAccounts, open, handleCloseCreate, reFetchData }: Props) => {
  const classes = useStyles();
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const [subAccountCategories, setSubAccountCategories] = useState<AccountCategoryModel[]>([]);
  const [tab, setTab] = React.useState<number>(0);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const handleTabs = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setTab(newValue);
  };

  useEffect(() => {
    // Reset subAccountCategories before fetching new data
    setSubAccountCategories([]);

    const fetchDataByTab = async () => {
      try {
        let data;
        switch (tab) {
          case 0:
            data = await getTaxExpenditure();
            break;
          case 1:
            data = await getAssetPurchase();
            break;
          case 2:
            data = await getInstallmentPayments();
            break;
          case 3:
            data = await getDividendDistribution();
            break;
          default:
            return;
        }
        setSubAccountCategories(data.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataByTab();
  }, [tab]);

  const getAccountTax = async () => axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/tax`, { cancelToken });
  const getAccountPassiva = async () => axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/passiva`, { cancelToken });
  const getAccountLoan = async () => axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/loan`, { cancelToken });
  const getAccountCapitalInvestment = async () => axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/capital-investment`, { cancelToken });
  const getAccountOtherIncome = async () => axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/other-income`, { cancelToken });
  const getTaxExpenditure = async () => axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/tax-expenditure`, { cancelToken });
  const getAssetPurchase = async () => axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/asset-purchase`, { cancelToken });
  const getInstallmentPayments = async () => axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/installment-payments`, { cancelToken });
  const getDividendDistribution = async () => axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/dividend-distribution`, { cancelToken });

  const reMounted = () => {
    setIsSubmit(false);
  };

  const handleCloseCreates = () => {
    reMounted();
    handleCloseCreate();
  };

  return (
    <Dialog maxWidth={'sm'} disableBackdropClick={true} aria-labelledby='customized-dialog-title' open={open}>
      <DialogTitle>
        <Grid xs={12} container justify='space-between'>
          <Typography variant='h5'> Pengeluaran Lain</Typography>
          <IconButton onClick={handleCloseCreates} size='small'>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container justify='flex-start' item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container className={classes.tabPanel}>
            <Tabs value={tab} indicatorColor='primary' textColor='primary' onChange={handleTabs} aria-label='tab-produk'>
              <Tab
                icon={
                  <Tooltip title='Pajak' placement='top'>
                    <AttachMoneyIcon />
                  </Tooltip>
                }
                className={classes.tab}
              />
              <Tab
                icon={
                  <Tooltip title='Pembelian Activa' placement='top'>
                    <AccountBalanceWalletOutlinedIcon />
                  </Tooltip>
                }
                className={classes.tab}
              />
              <Tab
                icon={
                  <Tooltip title='Pembayaran Cicilan' placement='top'>
                    <AssignmentOutlinedIcon />
                  </Tooltip>
                }
                className={classes.tab}
              />

              <Tab
                icon={
                  <Tooltip title='Pembagian Dividen' placement='top'>
                    <BallotOutlinedIcon />
                  </Tooltip>
                }
                className={classes.tab}
              />
            </Tabs>
          </Grid>
        </Grid>

        {tab === 0 && (
          <CreateTaxExpenditure
            financialAccounts={financialAccounts}
            subAccountCategories={subAccountCategories}
            handleCloseCreate={handleCloseCreate}
            reFetchData={reFetchData}
          />
        )}

        {tab === 1 && (
          <CreateAssetPurchase
            financialAccounts={financialAccounts}
            subAccountCategories={subAccountCategories}
            open={open}
            handleCloseCreate={handleCloseCreate}
            reFetchData={reFetchData}
          />
        )}

        {tab === 2 && (
          <CreateInstallmentPayments
            financialAccounts={financialAccounts}
            subAccountCategories={subAccountCategories}
            handleCloseCreate={handleCloseCreate}
            reFetchData={reFetchData}
          />
        )}

        {tab === 3 && (
          <CreateDividendDistribution
            financialAccounts={financialAccounts}
            subAccountCategories={subAccountCategories}
            handleCloseCreate={handleCloseCreate}
            reFetchData={reFetchData}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default memo(OtherExpensesCreate);
