import React, { FC, useEffect, useCallback, useState } from 'react';
import { Grid, makeStyles, Container, Typography, Button, Link, Breadcrumbs, Tabs, Tab } from '@material-ui/core';
import { Page, StandardConfirmationDialog, PaperCustom } from 'components';
import { AddBox } from '@material-ui/icons';
import axios, { CancelTokenSource } from 'axios';
import { ACCOUNT_CATEGORY_BASE_URL } from 'constants/url';
import useDebounce from 'hooks/useDebounce';
import AccountCategoriesTable from './components/AccountCategoriesTable';
import CreateAccountCategories from './components/CreateAccountCategories';
import Pagination from '@material-ui/lab/Pagination';
import SearchInput from 'components/SearchInput';
import useRouter from 'hooks/useRouter';
import PaginationCustom from 'components/PaginationCustom';
import OperationalPage from 'pages/OperationalPage';
import TransactionJurnalPage from 'pages/TransactionJurnalPage';

const useStyles = makeStyles({
  active: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px'
  },
  breadCrumb: {
    marginTop: '3px'
  },
  tabPanel: {
    flexGrow: 1,
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'flex-start'
  }
});

const dummyAccountCategory: AccountCategoryModel = {
  id: 0,
  name: '',
  category: '',
  number: 0,
  subAccount: 0,
  isEditable: true,
  isActive: true,
  subItemAccount: []
};

interface RoleModel {
  name: string;
}

const AccountCategoriesPage: FC = () => {
  const classes = useStyles();
  const { history } = useRouter();
  const [open, setOpen] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [accountCategories, setAccountCategories] = useState<AccountCategoryModel[]>([dummyAccountCategory]);
  // const [operasionalResource, setOperasionalResource] = useState<TypeOperasionalResource>({
  //   isLoading: false,
  //   data: []
  // });
  const [wareHouse, setWareHouse] = useState<WareHouseModel | null>(null);
  const [subAccountCategories, setSubAccountCategories] = useState<AccountCategoryModel[]>([dummyAccountCategory]);
  const [accountCategorie, setAccountCategorie] = useState<AccountCategoryModel | null>(null);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [name, setName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [selectedId, setSelectedId] = useState<number>();
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [type, setType] = useState<string>('SALES');
  const [tab, setTab] = React.useState<number>(0);
  const [isPackage, setPackage] = useState<boolean>(false);
  const [typeError, setTypeError] = useState<string>('');
  const [roles, setRoles] = useState<RoleModel[]>([
    { name: 'Beban Penyusutan' },
    { name: 'Keuntungan dari Penjualan Aktiva' },
    { name: 'Piutang Non-Usaha' },
    { name: 'Beban Non-Operasional' },
    { name: 'Piutang Usaha' },
    { name: 'Hutang Usaha' },
    { name: 'Kas' },
    { name: 'Pendapatan' },
    { name: 'Beban Operasional' },
    { name: 'Beban Lain-lain' },
    { name: 'Persediaan' },
    { name: 'Pajak Dibayar Di Muka' },
    { name: 'Aktiva Tetap' },
    { name: 'Pendapatan Lain-lain' },
    { name: 'Kerugian dari Penjualan Aktiva' },
    { name: 'Bank' },
    { name: 'Beban Pajak' },
    { name: 'Hutang Non-Usaha' },
    { name: 'Kewajiban Jangka Panjang' },
    { name: 'Laba' },
    { name: 'Akumulasi Penyusutan' },
    { name: 'Harga Pokok Penjualan' },
    { name: 'Hutang Pajak' },
    { name: 'Modal' },
    { name: 'Hutang Giro' },
    { name: 'Hutang Bank' },
    { name: 'Hutang Lain-Lain' },
    { name: 'Biaya Kantor' },
    { name: 'Ikhtisar R/L' },
    { name: 'Piutang Giro' },
    { name: 'Piutang karyawan' },
    { name: 'Panjar Pembelian' },
    { name: 'Panjar Penjualan' }
  ]);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('keyword', queryString);
      }

      if (name) {
        params.append('keyword', name);
      }

      if (address) {
        params.append('keyword', address);
      }

      if (orderBy || order) {
        params.append('orderBy', 'number');
        params.append('ordered', 'asc');
      }

      params.append('page', currentPage.toString());

      return params.toString();
    };

    try {
      const { data } = await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });

      setAccountCategories(data.data);
      setSubAccountCategories(data.data);
      setCount(data.meta.last_page);
      setCurrentPage(data.meta.current_page);
    } catch (error) {}
    setIsLoadingData(false);
    return () => {
      cancelTokenSource.cancel();
    };
  }, [queryString, open, orderBy, order, name, address, currentPage, rowsPerPage]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleSearch = useCallback((searchQuery: string) => {
    performActionAndRevertPage(setQueryString, searchQuery);
  }, []);

  const debouncedSearchTerm = useDebounce(query, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleConfirmationDelete = (id: number): React.MouseEventHandler => () => {
    setSelectedId(id);
    setConfirmationDelete(true);
  };

  const handleTabs = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setTab(newValue);

    setPackage(newValue === 1);
  };

  const handleOnUpdate = (id: number): React.MouseEventHandler => () => {
    setOpen(true);
    setAccountCategorie(accountCategories[id]);
  };

  const handleOnStatusChange = (id: number): React.MouseEventHandler => async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      const { data } = await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/status/${id}`, {
        cancelToken: cancelTokenSource.token
      });
      setAccountCategories(prevCategories =>
        prevCategories.map(
          category => (category.id === data.data.id ? { ...data.data } : category) // Ganti kategori dengan data yang diperoleh jika id cocok
        )
      );
    } catch (err) {
      console.log(err);
    }
  };

  const onUpdateSubAccount = (id: number): React.MouseEventHandler => async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    try {
      const { data } = await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/${id}`, {
        cancelToken: cancelTokenSource.token
      });
      setOpen(true);
      setAccountCategorie(data.data);
      fetchData();
      setOpenCollapse(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const deleteWareHouse = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      await axios.delete(`${ACCOUNT_CATEGORY_BASE_URL}/${selectedId}`, { cancelToken: cancelTokenSource.token });
      setAccountCategories(accountCategories.filter(value => value.id !== selectedId));
    } catch (err) {
      console.log(err);
    }
    setConfirmationDelete(false);
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenForm = (): void => {
    setOpen(true);
  };
  const onClickHandler = (path: string): React.MouseEventHandler => event => {
    event.preventDefault();
    history.push(path);
  };
  const getData = async (id: number) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/akun/${id}`, { cancelToken: cancelTokenSource.token });
      setSubAccountCategories(accountCategories.filter(value => value.id !== selectedId));
    } catch (err) {
      console.log(err);
    }
    setConfirmationDelete(false);
  };
  const handleOpenCollapse = (index: number, id: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
    getData(id);
  };

  return (
    <Page title='Bagan Akun'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Akunting </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumbs key={1} aria-label='breadcrumb' className={classes.breadCrumb}>
              <Link key={99} color='inherit' href='/' onClick={onClickHandler('/')}>
                Beranda
              </Link>
              <Link color='inherit' href='/' onClick={onClickHandler('/pengaturan')}>
                Akunting
              </Link>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <PaperCustom>
          <Grid container justify='flex-start' item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container className={classes.tabPanel}>
              <Tabs value={tab} indicatorColor='primary' textColor='primary' onChange={handleTabs} aria-label='tab-produk'>
                <Tab label='Bagan Akun Standart' />
                <Tab label='Transaksi Jurnal Umum' />
              </Tabs>
            </Grid>
          </Grid>
          {tab === 0 ? (
            <Grid container direction='row' spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container direction='row' justify='space-between'>
                  <Grid container item lg={8} sm={8} md={8} justify='flex-start' alignItems='center'>
                    <Grid item>
                      <SearchInput
                        withBorder
                        withTransition={false}
                        width={150}
                        placeHolder='Cari Akun'
                        iconColor='#989898'
                        tableSearchValue={query}
                        setTableSearchValue={setQuery}
                      />
                    </Grid>
                  </Grid>

                  <Grid container item lg={4} sm={4} md={4} justify='flex-end' alignItems='center'>
                    <Grid item>
                      <Button disabled={isLoadingData} onClick={handleOpenForm}>
                        <AddBox fontSize='small' /> &nbsp; Tambah COA Baru
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container justify='center' item xl={12} lg={12} md={12} sm={12} xs={12}>
                <AccountCategoriesTable
                  openCollapse={openCollapse}
                  indexCollapse={indexCollapse}
                  accountCategories={accountCategories}
                  isLoadingData={isLoadingData}
                  queryString={queryString}
                  subAccountCategories={subAccountCategories}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  handleConfirmationDelete={handleConfirmationDelete}
                  onUpdateSubAccount={onUpdateSubAccount}
                  handleOnUpdate={handleOnUpdate}
                  handleOnStatusChange={handleOnStatusChange}
                  handleOpenCollapse={handleOpenCollapse}
                />
              </Grid>

              <Grid container justify='flex-end' item xl={12} md={12} sm={12}>
                <PaginationCustom
                  marginTop='-.4em '
                  show={accountCategories.length > 0}
                  sxPagination={{
                    count,
                    boundaryCount: 2,
                    variant: 'outlined',
                    shape: 'rounded',
                    onChange: (event, page) => {
                      setCurrentPage(page);
                    },
                    page: currentPage
                  }}
                  sxPopover={{
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'right'
                    },
                    transformOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right'
                    }
                  }}
                  customPageProps={{
                    defaultValue: currentPage,
                    maxValue: count,
                    onSubmit(value) {
                      setCurrentPage(value);
                    }
                  }}
                />
              </Grid>

              <CreateAccountCategories
                type={type}
                setType={setType}
                typeError={typeError}
                roles={roles}
                open={open}
                setOpen={setOpen}
                accountCategories={accountCategories}
                accountCategorie={accountCategorie}
                setAccountCategories={setAccountCategories}
                setAccountCategorie={setAccountCategorie}
                handleSnackBar={handleSnackBar}
              />

              <StandardConfirmationDialog
                variant={snackbarVariant}
                titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
                message={message}
                open={openSnackbar}
                handleClose={handleCloseSnackbar}
                onConfirm={handleCloseSnackbar}
                noCancelButton={true}
              />

              <StandardConfirmationDialog
                variant={'danger'}
                titleMessage={'Menghapus Data'}
                message={'Apakah kamu yakin akan menghapus data ini?'}
                open={confirmationDelete}
                handleClose={handleCloseConfirmationDelete}
                onConfirm={deleteWareHouse}
              />
            </Grid>
          ) : (
            <TransactionJurnalPage />
          )}
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default AccountCategoriesPage;
